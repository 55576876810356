<template>
    <tr class='text-xs lg:text-sm'>
      <td class='py-2 px-3 text-left whitespace-no-wrap'>{{entity.company_code}}</td>
      <td class='py-2 px-3 text-left whitespace-no-wrap'>{{entity.entity_name}}</td>
      <td class='py-2 px-3 text-left whitespace-no-wrap'>{{entity.company_type}}</td>
      <td class='py-2 px-3 text-left whitespace-no-wrap'>{{entity.company_number}}</td>
      <td class='py-2 px-3 text-left whitespace-no-wrap'>{{entity.business_license_number}}</td>
      <td class='py-2 px-3 text-left whitespace-no-wrap'>{{entity.ceo_name}}</td>
      <td class='py-2 px-3 text-left whitespace-no-wrap'>{{entity.sector_large}}</td>
    </tr>
</template>

<script>
export default {
  name: 'EntitiesRowInvestmentMode',
  props: [
    'entity'
  ],
}
</script>
